import * as Msdyn365 from '@msdyn365-commerce/core';
import { mWPProcessRestrictedProductsAsync } from './DataActionExtension.g';
import { IMWPRestrictedProductsEntity, IProductStatus } from './DataServiceEntities.g';

/**
 * GetRestrictedProducts Input Action
 */
export class GetRestrictedProductsInput implements Msdyn365.IActionInput {
    public restrictedProductsEntity: IMWPRestrictedProductsEntity;

    constructor(restrictedProductsEntity: IMWPRestrictedProductsEntity) {
        this.restrictedProductsEntity = restrictedProductsEntity;
    }

    public getCacheKey = () => `GetRestrictedProducts-${this.restrictedProductsEntity.State || 'default'}`;
    public getCacheObjectType = () => 'RestrictedProducts';
    public dataCacheType = (): Msdyn365.CacheType => 'application';
}

/**
 * Data model for restricted products
 */
export interface IGetRestrictedProductsData {
    products: IProductStatus[];
}

/**
 * Create input for the action
 */
const createInput = (args: Msdyn365.ICreateActionContext): Msdyn365.IActionInput => {
    const restrictedProductsEntity: IMWPRestrictedProductsEntity = {
        State: args.requestContext.query?.state || '',
        StateID: args.requestContext.query?.stateId || '',
        Zip: args.requestContext.query?.zip || '',
        CartID: args.requestContext.query?.cartId || ''
    };
    return new GetRestrictedProductsInput(restrictedProductsEntity);
};

/**
 * Fetch restricted products using mWPProcessRestrictedProductsAsync
 */
async function action(input: GetRestrictedProductsInput, ctx: Msdyn365.IActionContext): Promise<IGetRestrictedProductsData> {
    const customContext = {
        ...ctx,
        callerContext: ctx
    };

    try {
        const response = await mWPProcessRestrictedProductsAsync(customContext, input.restrictedProductsEntity);
        return { products: response.productStatuses || [] };
    } catch (error) {
        ctx.telemetry.error(`Failed to process restricted products: ${error.message}`);
        throw error;
    }
}

export default Msdyn365.createObservableDataAction({
    action: <Msdyn365.IAction<IGetRestrictedProductsData>>action,
    id: 'GetRestrictedProducts',
    input: createInput,
    isBatched: false
});
